import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import {
  DataProvider,
  ElectionStyleThemeProvider,
  AllowTestDataProvider,
  LanguageProvider,
} from "election-visualization-library"
import Loading from "js/components/loading"
import useSlimFederatedElections from "js/hooks/use_slim_federated_elections"
import { orderSlots } from "js/utils/layouts"
import { VisualizationGrid } from "../layout_management/styles"
import CustomCSSWrapper from "../custom_css_wrapper"
const Slot = styled.div`
  min-width: 0;
`

function Layout({ grid, theme, isTest, defaultLanguage = "en" }) {
  const { slots = [], language = "" } = grid
  const { globalCSS, ...fontsAndColors } = theme

  const selectedDates = Array.from(new Set(slots.map((s) => s.date)))
  const elections = useSlimFederatedElections(selectedDates)

  const customCSS = [globalCSS, grid.customCSS]
    .filter((S) => Boolean((S ?? "").trim()))
    .join("\n\n")

  return (
    <AllowTestDataProvider allowTestData={true}>
      <DataProvider
        dataProviderUrlPrefix={`${
          isTest ? DATA_TEST_BASE_URL : DATA_LIVE_BASE_URL
        }/`}
      >
        <ElectionStyleThemeProvider theme={fontsAndColors}>
          <CustomCSSWrapper styles={customCSS}>
            <VisualizationGrid className="visualization-container">
              {orderSlots(slots).map((slot, i) => {
                const { date, visualization, col, row } = slot
                const slotProps = {
                  date: date,
                  ...slot.props,
                }
                const slotStyle = {
                  gridColumn: col.join("/"),
                  gridRow: row.join("/"),
                }

                const Election = elections[date] || {}
                return (
                  <Slot key={i} style={slotStyle}>
                    {Election.ready ? (
                      <LanguageProvider
                        orgLocale={slot.language || language || defaultLanguage}
                      >
                        <Election.Visualization
                          visualization={visualization}
                          props={slotProps}
                        />
                      </LanguageProvider>
                    ) : (
                      <Loading customClass="loading-visualization" />
                    )}
                  </Slot>
                )
              })}
            </VisualizationGrid>
          </CustomCSSWrapper>
        </ElectionStyleThemeProvider>
      </DataProvider>
    </AllowTestDataProvider>
  )
}

Layout.propTypes = {
  /** The layout grid configuration */
  grid: PropTypes.shape({
    /** The slots in the layout grid */
    language: PropTypes.string,
    slots: PropTypes.arrayOf(
      PropTypes.shape({
        /** The date of the election providing this visualization */
        date: PropTypes.string.isRequired,
        /** The name of the visualization type */
        visualization: PropTypes.string.isRequired,
        /** Props to be passed to the visualization itself */
        props: PropTypes.object.isRequired,
        /** The start/end column lines for this slot */
        col: PropTypes.arrayOf(PropTypes.number),
        /** The start/end row lines for this slot */
        row: PropTypes.arrayOf(PropTypes.number),
      }),
    ).isRequired,
    customCSS: PropTypes.string,
  }).isRequired,
  /** Election style theme to use */
  theme: PropTypes.object,
  /** Whether to show test data */
  isTest: PropTypes.bool,
  /** Whether to switch source to the new service */
  isNext: PropTypes.bool,
  /** Organizations default language */
  defaultLanguage: PropTypes.string,
}

export default Layout

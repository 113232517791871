import React from "react"
import { getDisplayName } from "./utils"
import ErrorBoundary from "js/containers/error_boundary"

export default function withRouteErrorBoundary(errorBoundaryProps = {}) {
  return (Component) => {
    function C(props) {
      return (
        <ErrorBoundary {...errorBoundaryProps}>
          <Component {...props} />
        </ErrorBoundary>
      )
    }

    C.displayName = `WithErrorBoundary(${getDisplayName(Component)})`

    return C
  }
}

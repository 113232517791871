import styled from "styled-components"

export const Container = styled.div`
  border: 1px solid #f0f0f0;
  padding: 2em;
  box-shadow:
    0 4px 6px -1px rgb(0 0 0 / 0.1),
    0 2px 4px -2px rgb(0 0 0 / 0.1);
  text-align: center;
`

/**
 * Order by row & col so that slots are stacked in a logical order on mobile
 *
 * @param {*} slots
 * @returns
 */
export const orderSlots = (slots) =>
  slots.slice().sort((a, b) => {
    // Index 0 has starting row or col
    if (a.row[0] !== b.row[0]) return a.row[0] - b.row[0]
    return a.col[0] - b.col[0]
  })

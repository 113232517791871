import { css } from "styled-components"

const fallbacks = "Verdana, sans-serif"

export const fontRegular = css`
  font-family: "AP", ${fallbacks};
  font-weight: 400;
  font-stretch: 54%;
`

export const fontBold = css`
  font-family: "AP", ${fallbacks};
  font-weight: 700;
  font-stretch: 54%;
`

export const fontItalic = css`
  font-family: "AP Italic", ${fallbacks};
  font-style: italic;
  font-stretch: 54%;
`

// @font-face {
//   font - family: 'AP Font';
//   src: url('https://mapi.associatedpress.com/assets/fonts/APVarW05-Regular.woff') format('woff'),
//     url('https://mapi.associatedpress.com/assets/fonts/APVarW05-Regular.woff2') format('woff2'),
//       url('https://mapi.associatedpress.com/assets/fonts/APVarW05-Regular.woff2') format('woff2-variations');
//   font - weight: 100 900;
//   font - stretch: 35 % 100 %;
// }

// /**************************  Font classes ***************************/

// .ap-font {
//   font-family: 'AP Font', Verdana, sans-serif;
//   font-weight: 400;
//   font-stretch: 80%;
// }

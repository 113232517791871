/**
 * Regular expression escaping function from MDM:
 *
 *   https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
 *
 * @param {string} string The string to escape
 * @returns {string} An escaped string
 */
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") // $& means the whole matched string
}

/**
 * Expects `pattern` to be a domain matching string. It may include `*`
 * wildcards, which are expected to match zero or more character in the domain
 * itself. All other characters in the pattern are escaped and must match
 * exactly. In total, the entire domain must be matched.
 *
 * @param {string} domain The current domain being checked for validity
 * @param {string} patter The domain pattern to validate against
 * @returns {boolean}
 */
function matchesDomainPattern(domain, pattern) {
  const regexString = pattern.split("*").map(escapeRegExp).join(".*")
  const re = new RegExp(`^${regexString}$`)
  return re.test(domain)
}

function handleReferrer(docReferrer, domainsToCheck) {
  const referrer = new URL(docReferrer).host
  const isValid = domainsToCheck.some((pattern) =>
    matchesDomainPattern(referrer, pattern),
  )
  return { isValid, referrer }
}

/**
 * Validate the current document's referrer against the organization's list of
 * allowed domains. If no list of domains is given or if the document has no
 * referrer then validation fails.
 *
 * @param {string[]} domains An array of domain patterns
 * @returns {boolean}
 */
export function validateDomains(domains) {
  return new Promise(function (resolve) {
    if (!domains) return { isValid: false }

    const domainsToCheck = [
      ...domains,
      "*.ap.org",
      "*.aptechlab.com",
      "*.aptechdevlab.com",
      "localhost:*",
    ]

    const docReferrer = document.referrer

    if (docReferrer) {
      resolve(handleReferrer(docReferrer, domainsToCheck))
    } else {
      requestDomainFromParent()
        .then((referrer) => {
          resolve(handleReferrer(referrer, domainsToCheck))
        })
        .catch((e) => {
          console.error(e)
          resolve({ isValid: false })
        })
    }
  })
}

let attachedListener

function requestDomainFromParent() {
  return new Promise(function (resolve, reject) {
    // We setup an event handler to receive a domain through post message
    if (attachedListener) {
      window.removeEventListener("message", attachedListener)
    }
    function receiveDomain(event) {
      attachedListener = receiveDomain
      if (event.data.type === "domain-request") {
        resolve(event.origin)
        //as soon as we receive the domain, we remove the event listener
        window.removeEventListener("message", receiveDomain)
      }
    }
    // Add event listener with our handler
    window.addEventListener("message", receiveDomain)

    // Set a timeout to reject the promise if it doesn't work
    window.setTimeout(() => {
      reject("Parent is not valid.")
    }, 2000)

    // After setting up to receive, we  send a message to the parent window
    window.parent.postMessage(
      {
        type: "domain-request",
      },
      "*",
    )
  })
}

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useGAInit } from "js/hooks/analytics"
import Loading from "js/components/loading"
import { validateDomains } from "./validate_domains"
import Layout from "./Layout"

function ClientLayout({ organization: organizationId, grid }) {
  const url = window.location.search
  const params = new URLSearchParams(url)
  const isTest = params.get("test") === "true"
  const [organization, setOrganization] = useState(null)
  const [analyticsEnabled, setAnalyticsEnabled] = useState(false)
  const [status, setStatus] = useState("loading")
  const [error, setError] = useState("")
  const [theme, setTheme] = useState({})

  useEffect(() => {
    // If organization is already set, just return
    if (organization) {
      return
    }
    //URL for the organization's config
    const configUrl = `${CUSTOMER_LAYOUTS_BASE_URL}/config/${organizationId}/config.json`
    let themeId // we'll need to pass this between .then() calls
    fetch(configUrl)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error("Could not load organization configuration.")
        }
      })
      .then(function (organization) {
        //set organization and start domain validation
        setOrganization(organization)
        setAnalyticsEnabled(organization.allowsAnalytics)
        themeId = organization.defaultThemeId
        return validateDomains(organization.domains)
      })
      .then(function (domainValidation) {
        //handle domain validation and load theme if domain is valid
        const { isValid, referrer } = domainValidation
        if (isValid && themeId) {
          const themeUrl = `${CUSTOMER_LAYOUTS_BASE_URL}/themes/${organizationId}/${themeId}.json`
          fetch(themeUrl).then(function (response) {
            if (response.ok) {
              response.json().then(({ theme }) => {
                setTheme(theme)
                setStatus("valid")
              })
            } else {
              setStatus("valid") // we just load the unthemed embed if there's an error with the theme
            }
          })
        } else if (isValid) {
          setStatus("valid") // we just load the unthemed embed if there's no theme
        } else {
          // handle error state for invalid domain
          throw new Error(
            `This AP embed is not registered for the following domain: ${referrer}`,
          )
        }
      })
      .catch(function (error) {
        //catch all error state
        setStatus("error")
        setError(error.message)
      })
  }, [setOrganization, organizationId, organization])

  useGAInit("G-SMVL7PVCND", { analyticsEnabled })

  const defaultLanguage =
    organization?.language || organization
      ? organization.languages
        ? organization.languages[0]
        : undefined
      : undefined

  switch (status) {
    case "loading":
      return <Loading />
    case "error":
      return <div>{error}</div>
    case "valid":
      return (
        <Layout
          grid={grid}
          theme={theme}
          isTest={isTest}
          defaultLanguage={defaultLanguage}
        />
      )
  }
}

ClientLayout.propTypes = {
  /** The newsroom organization ID */
  organization: PropTypes.number,
  /** The layout grid configuration */
  grid: PropTypes.object.isRequired,
}

export default ClientLayout

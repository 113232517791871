import styled from "styled-components"
import { fontRegular, fontBold } from "js/utils/styles"

const spacing = "letter-spacing: -0.023rem;"

export const H1 = styled.h1`
  ${fontBold}
  ${spacing}
  color: #2C2C2C;
  margin: 0.3125rem 0 1rem 0;
  padding: 0;
  line-height: 2.25rem;
  font-size: 1.5rem;
  display: flex;
  gap: 0.25rem;
  align-items: center;
`

export const H2 = styled.h2`
  ${fontBold}
  ${spacing}
  color: #2C2C2C;
  margin: 0.25rem 0 0.25rem 0;
  line-height: 1.875rem;
  font-size: 1.25rem;
  padding: 0;
`

export const P = styled.p`
  ${fontRegular}
  ${spacing}
  margin: 1.25rem 0 0.625rem 0;
  max-width: 32.75rem;
  line-height: 1.5rem;
  font-size: 1rem;
  padding: 0;
  color: #000;
`

export const Page = styled.div`
  ${fontRegular}
  padding: 1rem 0;
`

const variantStyles = {
  primary: {
    bgColor: "rgba(16, 75, 165, 1)",
    color: "#fff",
  },
  clear: {
    bgColor: "#fff",
    color: "rgba(16, 75, 165, 1)",
    borderColor: "rgba(16, 75, 165, 1)",
  },
  disabled: {
    bgColor: "#B5B5B6",
    borderColor: "#B5B5B6",
    color: "#FFF",
  },
  secondary: {
    bgColor: "#f0f0f0",
    color: "#888",
  },
  delete: {
    bgColor: "#ff0000",
    color: "#fff",
  },
}

export const Button = styled.button`
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8125rem 1.5rem;
  box-sizing: border-box;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background-color: ${(props) =>
    props.disabled
      ? variantStyles.disabled.bgColor
      : variantStyles[props.$variant || "primary"].bgColor};
  border: 1px solid
    ${(props) =>
      props.disabled
        ? variantStyles.disabled.borderColor
        : variantStyles[props.$variant || "primary"].borderColor ||
          variantStyles[props.$variant || "primary"].bgColor};
  border-radius: 0.25rem;
  color: ${(props) => variantStyles[props.$variant || "primary"].color};
  ${fontRegular}
  font-weight: 550;
  line-height: 1.5em;
  transition: opacity 0.2s;

  &:hover,
  &:focus {
    color: ${(props) =>
      props.disabled
        ? variantStyles.disabled
        : variantStyles[props.$variant || "primary"].color};
    opacity: ${(props) => (props.disabled ? 1 : 0.6)};
  }
`

// Export vis grid gap so we can use it in drop target styles
export const VIS_GRID_GAP = "2em"

export const VisualizationGrid = styled.div`
  background-color: ${(props) => props.$backgroundColor || "transparent"};
  min-height: ${(props) => (props.$empty ? "12.5rem" : undefined)};
  padding: 0.5em;
  display: grid;
  gap: ${VIS_GRID_GAP};
  grid-template-columns: ${(props) => `repeat(${props.$cols || 2}, 1fr)`};

  @media (prefers-color-scheme: dark) {
    background-color: #fff;
  }

  @media (max-width: 37.5rem) {
    display: flex;
    flex-direction: column;
  }
`

export const CodeInput = styled.textarea`
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  font-size: 1rem;
  color: #707070;
  border: 1px solid #e3e3e3;
  background-color: #fff;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  width: 100%;
  resize: none;
`

/** SETTING PAGES */

export const LanguageSelector = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  & > *:first-child {
    margin: 0;
    padding: 0;
    flex: 0 0 24rem;
  }
  & > *:nth-child(2) {
    flex: 0 0 6rem;
  }
`

export const FieldRow = styled.div`
  display: flex;
  margin: 0.25rem 0;
  min-height: 2rem;
  align-items: center;
  gap: 1rem;
  &:hover {
    background-color: #ebf2ff;
  }
  & > *:first-child {
    margin: 0;
    padding: 0;
    flex: 0 0 12rem;
  }
  & > *:nth-child(2) {
    flex: 0 0 15rem;
  }
`

export const OptionsContainer = styled.div`
  margin-bottom: 4rem;
`
export const Label = styled.label`
  ${fontRegular}
  letter-spacing: -0.023rem;
  color: #2c2c2c;
`
export const Float = styled.div`
  position: sticky;
  bottom: 2rem;
  text-align: right;
  display: flex;
  justify-content: flex-end;
`

import { lazy, useState, useEffect } from "react"
import withSuspense from "js/hoc/with_suspense"
import withErrorBoundary from "js/hoc/with_error_boundary"
import { compose } from "js/hoc/utils"
import {
  loadElectionContainer,
  loadElectionModule,
} from "./federated_election_utils"

export default function useSlimFederatedElections(dates) {
  const [elections, setElections] = useState({})
  const serializedDates = JSON.stringify(dates)
  useEffect(() => {
    const dates = JSON.parse(serializedDates)
    const loadRemoteEntry = async (date) => {
      await __webpack_init_sharing__("default")

      const isCycle = /^cycle-/.test(date)

      let container
      try {
        container = await loadElectionContainer(date)
      } catch (error) {
        return setElections((els) => ({
          ...els,
          [date]: {
            failed: true,
            isCycle,
            error,
          },
        }))
      }

      await container.init(__webpack_share_scopes__.default)

      const Visualization = lazy(() =>
        loadElectionModule(container, "./Visualization"),
      )

      const enhanceViz = compose(
        withErrorBoundary({
          message: `We're sorry, but there was a problem with this visualization.`,
        }),
        withSuspense,
      )

      setElections((els) => ({
        ...els,
        [date]: {
          Visualization: enhanceViz(Visualization),
          ready: true,
          isCycle,
        },
      }))
    }
    dates.forEach((date) => loadRemoteEntry(date))
  }, [serializedDates])
  return elections
}
